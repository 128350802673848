import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import ReactMarkdown from 'react-markdown'
import { css, jsx } from '@emotion/core'

const Container = styled.div`
  padding: 10px;
  background: white;
  height: 100%;
  border-radius: 5px;
`

export default ({ data }) => (
  <Container>
    {data.heroImage && <Img alt={data.name} fluid={data.heroImage.fluid} />}
    <div>
      <h3
        css={css`
          line-height: 1.25;
          margin-top: 1.55rem;
          margin-bottom: 0.775rem;
        `}
      >{data.name}</h3>
      <ReactMarkdown source={data.shortBio.shortBio} />
    </div>
  </Container>
)
