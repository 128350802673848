import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { css, jsx } from '@emotion/core'
import Markdown from 'react-markdown'

class ArticleLinks extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allContentfulLink(sort: { fields: [createdAt], order: ASC }) {
              edges {
                node {
                  id
                  childContentfulLinkDescriptionTextNode {
                    description
                    childMarkdownRemark {
                      rawMarkdownBody
                    }
                  }
                }
              }
            }
            contentfulContentType(name: { eq: "Link" }) {
              description
            }
          }
        `}
        render={({ allContentfulLink, contentfulContentType }) => {
          return (
            <div
              css={css`
                background: white;
                margin-top: 20px;
                padding: 10px;
                border-radius: 5px;
                height: 500px;
                overflow-y: scroll;
              `}
            >
              <h2
                css={css`
                  margin: 0px;
                  margin-bottom: 10px;
                  font-size: 20px;
                  line-height: 1rem;
                `}
              >
                {contentfulContentType.description}
              </h2>
              <>
                {allContentfulLink.edges.map(({ node }) => (
                  <div
                    id={node.id}
                    css={css`
                      p {
                        margin-bottom: 10px;
                        font-size: 16px;
                        line-height: 1rem;
                      }
                    `}
                  >
                    <Markdown
                      source={
                        node.childContentfulLinkDescriptionTextNode.description
                      }
                    />
                    <hr />
                  </div>
                ))}
              </>
            </div>
          )
        }}
      />
    )
  }
}

export default ArticleLinks
