import React from 'react'
import { graphql } from 'gatsby'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import get from 'lodash/get'
import SEO from '../components/SEO'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import EmailForm from '../components/form/SubscribeMailChimp'
import ArticleLinks from '../components/ArticleLinks'

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
`

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location}>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              max-width: 400px;
            `}
          >
            <SEO />
            <Hero data={author.node} />
          </div>
          <div
            css={css`
              @media only screen and (min-width: 819px) {
                margin-left: 20px;
              }

              @media only screen and (max-width: 819px) {
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
              }
              max-width: 400px;
            `}
          >
            <h2>Recent Posts</h2>
            <List>
              {posts.map(({ node }) => {
                return (
                  <div>
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                    <hr />
                  </div>
                )
              })}
            </List>
          </div>
          <div
            css={css`
              @media only screen and (min-width: 1140px) {
                margin-left: 20px;
                max-width: 300px;
              }
              @media only screen and (max-width: 1140px) {
                margin-top: 10px;
                max-width: 400px;
              }
            `}
          >
            <EmailForm />
            <ArticleLinks />
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC}, limit: 3) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            description
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 380
              maxHeight: 380
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
